.home-page-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(243, 244, 248, 1);
  position: relative;
}

.home-page-record-number-container {
  position: absolute;
  width: 100%;
  bottom: 5%;

  font-family: "PingFangSC-Regular";
  font-size: 15px;
  text-align: center;
}

.home-page-record-number {
  color: #ffffff;
}

.home-page-record-number:hover {
  color: #1890ff;
}

.home-page-main-login-great-line-container {
  height: 100%;
  width: 600px;
  display: flex;
  align-items: center;
}

.home-page-main-login-great-line-text {
  width: 594px;
  height: 76px;
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 38px;
}

.home-page-main-login-main {
  width: 320px;
  height: 430px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-page-main-login-main-close {
  cursor: pointer;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 12px;
  top: 12px;
}

.home-page-main-login-wechat {
  width: 320px;
  height: 430px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.home-page-main-login-phone {
  width: 320px;
  height: 430px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.home-page-main-login-phone-phone-tip {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  color: rgba(54, 54, 63, 1);
  line-height: 42px;
  margin-top: 100px;
  margin-left: 34px;
}

.home-page-main-login-phone-phone-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.home-page-main-login-phone-phone-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-login-phone-phone-input {
  width: 220px;
  height: 42px;
  display: flex;
  border-bottom: 1px solid #eff0f4 !important;
}

.home-page-main-login-phone-verify-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  position: relative;
}

.home-page-main-login-phone-verify-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-login-phone-verify-input {
  width: 220px;
  height: 42px;
  display: flex;
  border-bottom: 1px solid #eff0f4 !important;
}

.home-page-main-login-phone-verify-button-container {
  align-items: center;
  position: absolute;
  right: 40px;
  bottom: 12px;
}

.home-page-main-login-phone-login-button-container {
  margin-top: 50px;
  width: 250px;
  height: 40px;
  background: rgba(54, 54, 63, 1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home-page-main-login-phone-login-button-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}

.home-page-main-login-main-register {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 21px;
  right: 20px;
  top: 20px;
  position: absolute;
}

.home-page-main-login-main-tip {
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.home-page-main-login-main-tip-text-1 {
  font-family: "PingFangSC-Semibold";
  font-size: 28px;
  color: rgba(54, 54, 63, 1);
  line-height: 42px;
  margin-top: 20px;
}

.home-page-main-login-main-tip-text-2 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 27px;
}

.home-page-main-login-main-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.home-page-main-login-main-button-login-background {
  width: 250px;
  height: 40px;
  background: rgba(54, 54, 63, 1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home-page-main-login-main-button-login-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.home-page-main-login-main-button-login-icon {
  width: 22px;
  height: 18px;
  margin-right: 12px;
}

.home-page-main-login-main-button-login-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}

.home-page-main-login-main-button-mobile-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 56px;
}

.home-page-main-register {
  width: 100%;
  height: 100%;
}

.home-page-main-register-title-container {
  width: 100%;
  height: 130px;
  background: #303443;
  position: relative;
}

.home-page-main-register-title-back {
  cursor: pointer;
  left: 30px;
  top: 16px;
  position: absolute;

  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}

.home-page-main-register-title-step-container {
  position: absolute;
  width: 100%;
  height: 34px;
  bottom: -17px;
  display: flex;
  justify-content: center;
}

.home-page-main-register-title-step {
  width: 438px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.home-page-main-register-title-step-item-container {
  width: 146px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.home-page-main-register-title-step-item-circle-1 {
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-main-register-title-step-item-circle-2 {
  background-color: #303443;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-main-register-title-step-item-circle-item {
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}

.home-page-main-register-title-step-item-circle-item-text-selected {
  top: -35px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 24px;
  position: absolute;
}

.home-page-main-register-title-step-item-circle-item-text {
  top: -35px;
  font-size: 16px;
  font-weight: 400;
  color: #bababa;
  line-height: 24px;
  position: absolute;
}

.home-page-main-register-body-container {
  height: calc(100% - 130px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-main-register-invitation-code-input-container {
  margin-top: 190px;
  height: 25px;
  width: 400px;
  display: flex;
  align-items: center;
}

.home-page-main-register-invitation-code-input-text {
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
}

.home-page-main-register-invitation-code-input-input {
  height: 25px;
  width: 330px;
  border-bottom: 1px solid #eff0f4 !important;
  margin-left: 20px;
}

.home-page-main-register-invitation-code-input-container .ant-input {
  border: none;
  box-shadow: none;
  outline: none;
}

.home-page-main-register-invitation-code-input-container .ant-input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.home-page-main-register-invitation-code-input-container.ant-input:hover {
  border: none;
  box-shadow: none;
  outline: none;
}

.home-page-main-register-invitation-code-buton-container {
  width: 400px;
  height: 40px;
  background: rgba(54, 54, 63, 1);
  border-radius: 20px;
  margin-top: 110px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-main-register-invitation-code-buton-text {
  font-size: 14px;
  font-weight: 400;
  color: #bababa;
  line-height: 21px;
}

.home-page-main-register-invitation-wechat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-main-register-invitation-loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-main-register-user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-main-register-user-info-avatar-background {
  width: 70px;
  height: 70px;
  margin-top: 70px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
}

.home-page-main-register-user-info-avatar-container {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(48, 52, 67, 0.5);
  position: absolute;
}

.home-page-main-register-user-info-avatar-container-none {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(48, 52, 67, 0.5);
  position: absolute;
}

.home-page-main-register-user-info-avatar-modify {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(48, 52, 67, 0.5);
}

.home-page-main-register-invitation-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-main-register-user-info-name-container {
  margin-top: 40px;
  margin-bottom: 0px;
  height: 25px;
  width: 420px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-name-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-register-user-info-name-input-container {
  margin-left: 20px;
  width: 350px;
  height: 42px;
  display: flex;
}

.home-page-main-register-user-info-name-input-container-focus {
  margin-left: 20px;
  width: 350px;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #eff0f4 !important;
  display: flex;
}

.home-page-main-register-user-info-name-input {
  width: 240px;
}

.home-page-main-register-user-info-name-input-tip {
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-pen-container {
  margin-top: 40px;
  margin-bottom: 0px;
  height: 25px;
  width: 420px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-pen-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-register-user-info-pen-input-container {
  margin-left: 20px;
  width: 240px;
  height: 42px;
  display: flex;
}

.home-page-main-register-user-info-pen-input-container-focus {
  margin-left: 20px;
  width: 240px;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #eff0f4 !important;
  display: flex;
}

.home-page-main-register-user-info-pen-input {
  width: 240px;
}

.home-page-main-register-user-info-phone-container {
  margin-top: 40px;
  margin-bottom: 0px;
  height: 25px;
  width: 420px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-phone-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-register-user-info-phone-input-container {
  margin-left: 20px;
  width: 240px;
  height: 42px;
  display: flex;
}

.home-page-main-register-user-info-phone-input-container-focus {
  margin-left: 20px;
  width: 240px;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #eff0f4 !important;
  display: flex;
}

.home-page-main-register-user-info-phone-input {
  width: 240px;
}

.home-page-main-register-user-info-verify-container {
  margin-top: 40px;
  margin-bottom: 0px;
  height: 25px;
  width: 420px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-verify-text {
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 24px;
  margin-bottom: 2px;
}

.home-page-main-register-user-info-verify-input-container {
  margin-left: 20px;
  width: 350px;
  height: 42px;
  display: flex;
}

.home-page-main-register-user-info-verify-input-container-focus {
  margin-left: 20px;
  width: 350px;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #eff0f4 !important;
  display: flex;
}

.home-page-main-register-user-info-verify-input {
  width: 240px;
}

.home-page-main-register-user-info-verify-input-tip {
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
  display: flex;
  align-items: center;
}

.home-page-main-register-user-info-buton-container {
  width: 400px;
  height: 40px;
  background: rgba(54, 54, 63, 1);
  border-radius: 20px;
  margin-top: 70px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-main-register-user-info-buton-text {
  font-size: 14px;
  font-weight: 400;
  color: #bababa;
  line-height: 21px;
}

/* 以下是antd全局样式修改 */
.home-page-main-container .ant-input {
  border: none;
  box-shadow: none;
  outline: none;
}

.home-page-main-container.ant-input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.home-page-main-container.ant-input:hover {
  border: none;
  box-shadow: none;
  outline: none;
}
