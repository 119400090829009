::-webkit-scrollbar {
  display: none;
}
.writing-main-body-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.writing-module-body {
  background: rgba(243, 244, 248, 1);
  display: flex;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.no-content-alert {
  z-index: 999;
  width: 342px;
  height: 139px;
  position: absolute;
  top: 80px;
  left: 42.5%;
}
.ce-inline-toolbar {
  display: none;
}
.writing-module-body .ce-block--focused {
  background-image: none !important;
}
.writing-module-body .ce-paragraph {
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 30px;
}
.feedback-re-submit {
  cursor: pointer;
  width: 66px;
  height: 24px;
  border-radius: 12px;
  background: rgba(48, 52, 67, 1);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 22px;
}
.feedback-proofread-all {
  cursor: pointer;
  width: 62px;
  height: 22px;
  border-radius: 12px;
  background: rgba(255, 115, 141, 1);
  text-align: center;
  margin-left: 6px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 22px;
}
.feedback-module-container {
  width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.writing-module-tree-node-input {
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-tree {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  margin: 0;
  padding: 0 0 30px 0;
}
.writing-module-body .ant-tree li .ant-tree-node-content-wrapper {
  -webkit-transition: unset;
  transition: unset;
}
.writing-module-tree-node-text {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5px;
}
.writing-module-tree-node-text-select {
  font-size: 12px;
  font-weight: 400;
  color: #f3f4f8;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5px;
}
.writing-module-tree-node-span {
  margin-left: -8px;
  height: 100%;
  display: flex;
  align-items: center;
}
.writing-module-proofreading-tab {
  margin: 0 auto;
  width: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-fullscreen-tab {
  display: flex;
  width: 100%;
  margin-left: auto;
  align-items: center;
  /*flex-flow: row nowrap;*/
  /*justify-content: flex-end;*/
}
.writing-module-cardMode-main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
  font-weight: bold;
  height: 30px;
  width: 100%;
  padding-left: 10px;
}
.writing-module-cardMode-fullscreen-button {
  height: 30px;
  border-right: 1px solid #d0d0d0;
  padding-right: 10px;
  padding-top: 2px;
  cursor: pointer;
}
.writing-module-menu-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
  font-weight: bold;
  height: 30px;
  padding-top: 3px;
  padding-right: 10px;
  padding-left: 10px;
}
.writing-module-menu-title-icon {
  padding-top: 3px;
  cursor: pointer;
}
.writing-module-menubar-not-hiding {
  padding-right: 5px;
  border-right: 1px solid #ecebeb;
  background: white;
  width: 260px;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.writing-module-menubar-not-hiding-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #ffffff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*.writing-module-menubar-not-hiding .ant-tree li {*/
/*  !*z-index: 997;*!*/
/*}*/
/*.writing-module-menubar-not-hiding .ant-tree li span[draggable], .ant-tree li span[draggable='true'] {*/
/*  border-bottom: none;*/
/*  border-top: none;*/
/*  line-height: 24px;*/
/*}*/
.ce-inline-toolbar {
  display: none;
}
.writing-module-menubar-hiding {
  border-right: 1px solid #ecebeb;
  margin-right: 10px;
  min-width: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.writing-module-menubar-hiding-icon {
  padding-top: 2px;
  height: 29px;
  cursor: pointer;
  padding-right: 10px;
}
.right-click-menu {
  z-index: 998;
  text-align: center;
  width: 150px;
  background: white;
  position: absolute;
  line-height: 25px;
  font-size: 13px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.writing-module-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}
.writing-module-title-text {
  font-family: "PingFangSC-Regular";
  font-size: 12px;
  color: #36363f;
  letter-spacing: 0.44px;
}
.writing-module-title-divider {
  border-left: 1px solid #eff0f4;
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.right-click-menu-li {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 10px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.right-click-menu-li:hover {
  background: rgba(243, 244, 248, 1);
}
.writing-module-outline-container {
  display: flex;
  align-items: center;
}
.writing-module-outline-text {
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
}
.writing-module-outline-add {
  cursor: pointer;
  width: 15px;
  height: 15px;
  flex-flow: row nowrap;
  margin: 0 0 0 10px;
}
.writing-module-tab-bar {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: bold;
  height: 30px;
  background: white;
  padding: 0px 10px 0 10px;
  z-index: 997;
}
.writing-module-proofreading-hide {
  display: flex;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  height: 30px;
  width: 140px;
  margin-left: 10px;
  padding-right: 30px;
  padding-left: 10px;
}
.writing-module-proofreading-hide-icon {
  width: 10px;
  cursor: pointer;
}
.writing-module-full-screen-button-5416 {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.writing-module-editor-container-no-full-screen {
  width: 700px;
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 30px;
}
.writing-module-empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback-panel {
  background: #5a5656;
}
.feedback-panel .open {
  background: #a0c8eb;
}
.writing-module-empty-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.writing-module-empty-icon-button {
  width: 150px;
  height: 50px;
  margin-top: 24px;
  background: rgba(48, 52, 67, 1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.writing-module-empty-icon-button .add {
  width: 16px;
  height: 16px;
  color: rgba(255, 255, 255, 1);
  transition: color 0.25s;
  position: relative;
  margin-right: 10px;
}
.writing-module-empty-icon-button .add::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  margin-left: -8px;
  margin-top: -1.5px;
  border-top: 3px solid;
  border-radius: 2px;
}
.writing-module-empty-icon-button .add::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 16px;
  margin-left: -1.5px;
  margin-top: -8px;
  border-left: 3px solid;
  border-radius: 2px;
}
.writing-module-empty-icon-button .text {
  font-size: 14px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 21px;
}
.writing-module-tools-tab-text {
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
  cursor: pointer;
}
.writing-module-tools-tab-text-selected {
  font-size: 12px;
  font-weight: bold;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
}
.full-screen-container-7413 {
  background-color: #f3f4f8;
  display: flex;
  justify-content: center;
}
.full-screen-editor-container-7413 {
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 30px;
}
.full-screen-editor-container-7413-full-screen {
  width: 65%;
}
.full-screen-editor-container-7413-show-markdown {
  display: none !important;
}
.full-screen-editor-container-7413 {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.full-screen-editor-container-7413::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.full-screen-editor-button-container-8756 {
  position: absolute;
  bottom: 30px;
  right: 50px;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.full-screen-editor-button-container-8823 {
  padding: 20px 10px 10px 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 30px;
}
.full-screen-editor-button-8904 {
  cursor: pointer;
  width: 36px;
  height: 36px;
}
.full-screen-toolbar-mouse-controller {
  position: absolute;
  width: 100%;
  height: 66px;
}
.full-screen-toolbar-container .ant-drawer-content {
  background-color: rgba(0, 0, 0, 0);
}
/*.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {*/
/*  box-shadow: none;*/
/*}*/
.tab-menu-container {
  width: 100%;
}
.card-freedom-container {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  padding-top: 30px;
}
.card-drag-container {
  display: inline-block;
  width: auto;
  position: relative;
  z-index: 0;
}
.card-title {
  display: flex;
  height: 31px;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid rgba(239, 240, 244, 1);
}
.card-title-text-container {
  display: flex;
  align-items: center;
}
.writing-module-feedback-main {
  width: 100%;
  height: 100%;
  border-left: 1px solid rgba(239, 240, 244, 1);
  background: white;
}
.card-item-main {
  width: 340px;
  height: 226px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
  color: black;
  opacity: 0.88;
  margin: 10px;
  cursor: pointer;
}
.card-item-main:hover {
  outline: 4px solid #dbeaff;
}
.card-item-main.folder {
  box-shadow: 0 0.0625em 0.1875em 0 rgba(0, 0, 0, 0.1),
    0 0.5em 0 -0.25em #f2f2f2, 0 0.5em 0.1875em -0.25em rgba(0, 0, 0, 0.1),
    0 1em 0 -0.5em #e5e5e5, 0 1em 0.1875em -0.5em rgba(0, 0, 0, 0.1);
}
.card-item-main-selected {
  width: 340px;
  height: 226px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  color: black;
  opacity: 0.88;
  margin: 10px;
  cursor: pointer;
  outline: 4px solid #c3c6d1;
}
.card-btn-container {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}
.card-btns {
  cursor: pointer;
  width: 15px;
  height: 15px;
  flex-flow: row nowrap;
  margin: 0 10px 0 0;
}
.card-btns:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card-text-area {
  font-size: 13px;
  padding: 14px 12px 20px 10px;
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.card-text {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #36363f;
  line-height: 17px;
}
.card-delete-btn {
  cursor: pointer;
  padding-top: 4px;
  font-size: 13px;
  padding-right: 10px;
}
.tab-menu-card-button-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tab-menu-card-name-text {
  padding-top: 1px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 18px;
}
.tab-menu-order {
  margin-right: 8px;
  cursor: pointer;
  padding-left: 8px;
  width: 64px;
  height: 18px;
  background: rgba(243, 244, 248, 1);
  font-size: 12px;
  font-weight: 500;
  color: rgba(54, 54, 63, 1);
  border-radius: 9px;
}
.tab-menu-card-button-sort-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #d0d0d0;
}
.tab-menu-card-button-sort-type-unselected {
  cursor: pointer;
}
.tab-menu-card-button-sort-type-selected {
  color: #000000;
}
.feedback-list-button {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 5px 5px 5px 10px;
}
.feedback-list-button.select {
  position: relative;
}
.feedback-list-button.select::after {
  /* border-bottom: 3px solid black; */
  content: "";
  position: absolute;
  width: 22px;
  height: 4px;
  background: rgba(54, 54, 63, 1);
  border-radius: 2px;
  bottom: -4px;
  left: calc(50% - 11px);
}
.feedback-toolbar-container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-module-main {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ff6e58;
}
.edit-module-edit-container {
  flex: 1;
}
.edit-module-edit {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
  font-size: 16px;
  font-weight: 400;
  color: rgba(54, 54, 63, 1);
  line-height: 30px;
}
.edit-module-edit .ce-block--focused {
  background-image: none !important;
}
.edit-module-tool-container {
  width: 400px;
  height: 100%;
}
.card-mode-text {
  font-size: 14px;
  font-weight: 500;
  color: #36363f;
  line-height: 21px;
}
.tab-card-count-container {
  display: flex;
  align-items: center;
}
.tab-card-count-text {
  font-size: 12px;
  font-weight: 400;
  color: rgba(186, 186, 186, 1);
  line-height: 18px;
  margin-left: 3px;
}
