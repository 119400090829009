.aw-error {
  cursor: pointer;
  text-decoration: line-through;
  font-weight: bold;
  background-color: #fff4a0;
}

.aw-search {
  background-color: #fff4a0;
}

.aw-replace {
  background-color: #fff4a0;
}

.aw-replace-selected {
  background-color: rgba(255, 115, 141, 0.7);
}

.aw-mark-editor-underline {
  border-bottom: 2px solid;
  background: unset !important;
}

.aw-mark-char-count-color-one_char {
  background: #82A1FF;
  border-bottom-color: #82A1FF;
}

.aw-mark-char-count-color-two_char {
  background: #2BDEB6;
  border-bottom-color: #2BDEB6;
}

.aw-mark-char-count-color-three_char {
  background: #FFCF00;
  border-bottom-color: #FFCF00;
}

.aw-mark-char-count-color-four_char {
  background: #FFA7EE;
  border-bottom-color: #FFA7EE;
}

.aw-mark-char-count-color-multi_char {
  background: #FF8484;
  border-bottom-color: #FF8484;
}

.aw-mark-char-pronounce-color-chi {
  background: #ff8484;
  border-bottom-color: #ff8484;
}

.aw-mark-char-pronounce-color-she {
  background: #ffd47b;
  border-bottom-color: #ffd47b;
}

.aw-mark-char-pronounce-color-ya {
  background: #2bdeb6;
  border-bottom-color: #2bdeb6;
}

.aw-mark-char-pronounce-color-chun {
  background: #ffa7ee;
  border-bottom-color: #ffa7ee;
}

.aw-mark-char-pronounce-color-hou {
  background: #82a1ff;
  border-bottom-color: #82a1ff;
}

.aw-mark-word-pos-color-prep {
  background: #FFF4B5;
  border-bottom-color: #FFF4B5;
}

.aw-mark-word-pos-color-adv {
  background: #FFE0E0;
  border-bottom-color: #FFE0E0;
}

.aw-mark-word-pos-color-conj {
  background: #F7E6D1;
  border-bottom-color: #F7E6D1;
}

.aw-mark-word-pos-color-noun {
  background: #D4E5BF;
  border-bottom-color: #D4E5BF;
}

.aw-mark-word-pos-color-verb {
  background: #96D7C9;
  border-bottom-color: #96D7C9;
}

.aw-mark-word-pos-color-adj {
  background: #C4E2E9;
  border-bottom-color: #C4E2E9;
}

.aw-mark-word-pos-color-pron {
  background: #ECCCE6;
  border-bottom-color: #ECCCE6;
}

.aw-mark-word-pos-color-per {
  background: #9CB7EC;
  border-bottom-color: #9CB7EC;
}

.aw-mark-word-pos-color-loc {
  background: #C7C9E8;
  border-bottom-color: #C7C9E8;
}

.aw-mark-word-pos-color-org {
  background: #E0CEC2;
  border-bottom-color: #E0CEC2;
}

.aw-mark-word-pos-color-time {
  background: #A7E8A8;
  border-bottom-color: #A7E8A8;
}

.aw-mark-sent-len-color-normal {
  background: #7ed321;
  border-bottom-color: #7ed321;
}

.aw-mark-sent-len-color-short {
  background: #ffa7ee;
  border-bottom-color: #ffa7ee;
}

.aw-mark-sent-len-color-long {
  background: #17d2ff;
  border-bottom-color: #17d2ff;
}

.aw-mark-sent-form-color-single {
  background: #FFCF00;
  border-bottom-color: #FFCF00;
}

.aw-mark-sent-form-color-complex {
  background: #82A1FF;
  border-bottom-color: #82A1FF;
}

.aw-mark-sent-pattern-color-exclamatory {
  background: #ffa7ee;
  border-bottom-color: #ffa7ee;
}

.aw-mark-sent-pattern-color-question {
  background: #17d2ff;
  border-bottom-color: #17d2ff;
}

.aw-mark-sent-pattern-color-imperative {
  background: #ffcf00;
  border-bottom-color: #ffcf00;
}

.aw-mark-sent-pattern-color-declarative {
  background: #2bdeb6;
  border-bottom-color: #2bdeb6;
}

.aw-mark-sent-meaning-color-positive {
  background: #7ed321;
  border-bottom-color: #7ed321;
}

.aw-mark-sent-meaning-color-negative {
  background: #ffa7ee;
  border-bottom-color: #ffa7ee;
}

.aw-mark-word-cloud-0 {
  background: #FFE0E0;
}

.aw-mark-word-cloud-1 {
  background: #F7E6D1;
}

.aw-mark-word-cloud-2 {
  background: #FFF4B5;
}

.aw-mark-word-cloud-3 {
  background: #D4E5BF;
}

.aw-mark-word-cloud-4 {
  background: #C4E2E9;
}

.aw-mark-word-cloud-5 {
  background: #96D7C9;
}

.aw-mark-word-cloud-6 {
  background: #E0CEC2 ;
}

.aw-mark-word-cloud-7 {
  background: #ECCCE6;
}

.aw-mark-word-cloud-8 {
  background: #C7C9E8 ;
}

.aw-mark-word-cloud-9 {
  background: #9CB7EC;
}

.aw-mark-word-meta {
  color: #ff6280;
  border-bottom: 2px solid #ff6280;
}

.aw-mark-word-meta-selected {
  background: rgba(255, 113, 140, 0.2);
  border-bottom: 2px solid #ff6280;
  color: #ff6280;
  cursor: pointer;
}

.aw-mark-word-replacement {
  color: #ffab21;
  border-bottom: 2px solid #ffab21;
}

.aw-mark-word-replacement-selected {
  background: rgba(255, 186, 45, 0.2);
  border-bottom: 2px solid #ffab21;
  color: #ffab21;
  cursor: pointer;
}