.web-panel {
    min-height: 30px;
    width: 315px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
}

.web-panel.opening {
    width: 305px;
    margin-bottom: 24px;
    background:rgba(249,249,251,1);
    border-radius:4px;
}

.web-panel .web-panel-header::after {
    display: inline-block;
    content:'';
    width: 25px;
    height: 25px;
    position: absolute;
    right:21px;
    top: 50%;
    margin-top: -13px;
}
/*.web-panel.closing > .web-panel-header::after {*/
/*    background: url('./images/closing.png') no-repeat center center;*/
/*    background-size: 100% 100%;*/
/*}*/
/*.web-panel.opening > .web-panel-header::after {*/
/*    background: url('./images/opening.png') no-repeat center center;*/
/*    background-size: 100% 100%;*/
/*}*/
.web-panel .web-panel-header {
    font-size: 15px;
    min-height: 40px;
    border-radius:8px;
    padding: 0 1px 0 0;
    color:#555151;
    position: relative;
}

.web-panel.closing .web-panel-header {
    word-break: break-all;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.web-panel.closing .web-panel-content {
    display: none;
    opacity: 0;
}

.web-panel.opening .web-panel-content {
    margin: 20px 3px 10px 20px;
    padding-right: 3px;
    padding-bottom: 10px;
    font-size:15px;
    color:#5a5656;
    line-height:28px;
    display: block;
}

.web-panel.opening .panel-index {
    display: block;
}

.web-panel.closing .panel-index {
    display: none;
}

.web-panel.opening .panel-inline-index {
    display: none;
}

.web-panel.closing .panel-inline-index {
    display: block;
}
